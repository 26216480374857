import { createFileRoute, useNavigate, useRouterState } from '@tanstack/react-router'
import { useCallback, useContext } from 'react'
import { MapRenderContext } from 'src/contexts/MapRenderContext'
import AutomaticSheet from 'src/components/sheet/AutomaticSheet'

import { useTranslation } from 'react-i18next'
import { Result, SearchSelect } from 'src/components/SearchSelect'

export const Route = createFileRoute('/search')({
    component: PlanSelectPoint,
})


function PlanSelectPoint() {
    const navigate = useNavigate()
    const { t } = useTranslation()
    const state = useRouterState()
    const { map, mapRef } = useContext(MapRenderContext)

    const onClick = useCallback((result: Result) => {
        const selected = { latitude: result.location?.latitude, longitude: result.location?.longitude, name: result.title, type: result.type, otp_code: result.id }
        
        console.log('Selected:', selected, 'mapRef:', mapRef)
        // Fly to the selected location
        if (selected.type == 'transit_stop') {
            navigate({ to: `/stop/${selected.otp_code}`})
        } else if (selected.type == 'rental_station') {
            navigate({ to: `/micromobility/${selected.otp_code}`})
        } else if (mapRef && mapRef.current) {
            map.current!.flyTo({
                center: [selected.longitude, selected.latitude],
                zoom: 16,
            })
        }

    }, [mapRef])

    return (
        <AutomaticSheet>
            <title>{t('Select location')}</title>
            <SearchSelect onSelect={onClick} />
        </AutomaticSheet>
    )
}