import { createFileRoute, useRouterState } from '@tanstack/react-router'
import { DateTime } from 'luxon'
import { useContext } from 'react'
import AutomaticSheet from 'src/components/sheet/AutomaticSheet'
import { MapRenderContext } from 'src/contexts/MapRenderContext'

import { useGetPlanPlanTransportModesFromLonLatToLonLatGet } from 'src/generated/brezavta/queries'

import Chip from '@mui/material/Chip'
import List from '@mui/material/List'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemText from '@mui/material/ListItemText'

import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { Box, CircularProgress } from '@mui/material'
import { useNavigate } from '@tanstack/react-router'
import { useTranslation } from 'react-i18next'
import PlanDisplay from 'src/components/plan/PlanDisplay'

export const Route = createFileRoute('/plan/$mode/$coordinates/$datetime/$type/$page/')({
    component: () => <Plan />,
})

function Plan() {
    const { map, mapRef } = useContext(MapRenderContext)
    const navigate = useNavigate()
    const state = useRouterState()
    const { mode, coordinates, datetime, type, page } = Route.useParams()

    // console.log('mode', mode);
    // console.log('coordinates', coordinates);
    // console.log('datetime', datetime);
    // console.log('type', type);
    // console.log('page', page);

    const { t } = useTranslation()

    const { data: plans, refetch } = useGetPlanPlanTransportModesFromLonLatToLonLatGet({
        path: {
            from_lon_lat: coordinates!.split(';')[0],
            to_lon_lat: coordinates!.split(';')[1],
            transport_modes: mode,
        },
        query: {
            date: datetime!.split('T')[0],
            time: datetime!.split('T')[1].substring(0, 5),
            page_cursor: page == 'null' ? '' : page,
            type: type,
        },
    })

    const gotoPage = (page: string) => {
        navigate({
            to: '/plan/' + mode + '/' + coordinates + '/' + datetime + '/' + type + '/' + page,

        })
    }

    if (!plans) {
        return (
            <AutomaticSheet>
                <Box sx={{ display: 'flex', height: window.innerHeight / 2, justifyContent: 'center', alignItems: 'center' }}>
                    <CircularProgress />
                </Box>
            </AutomaticSheet>
        )
    }

    const gotoPlanView = (plan: any, index: number) => {
        navigate({
            to: '/plan/' + mode + '/' + coordinates + '/' + datetime + '/' + type + '/' + page + '/' + index,
            state: {
                plan: plan,
            },
        })
    }

    function renderPlans(plans) {
        if (plans?.plans.length == 0) {
            return (
                <>
                    {t('No plans found')}
                </>
            )
        }
        return plans?.plans.map((plan, index) => (
            <>
                <ListItemButton
                    key={index}
                    sx={{
                        width: '100%',
                        borderBottom: '1px solid #f0f0f0',
                        flexDirection: 'column',
                        alignItems: 'space-between',
                    }}
                    onClick={() => gotoPlanView(plan, index)}
                >
                    <ListItemText
                        primary={(
                            <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
                                <span>
                                    {DateTime.fromMillis(plan.start_time, { zone: 'Europe/Ljubljana' }).toFormat('HH:mm') + ' - ' + DateTime.fromMillis(plan.end_time, { zone: 'Europe/Ljubljana' }).toFormat('HH:mm')}
                                </span>
                                <span style={{ float: 'right' }}>
                                    <Chip
                                        label={(
                                            // TODO: translate & pluralize
                                            <>
                                                {Math.ceil((plan.end_time - plan.start_time) / 60 / 1000) + ' min' + ` - ${plan.legs.length} legs`}
                                            </>
                                        )}
                                        variant="outlined"
                                        size="small"
                                    />
                                </span>
                            </div>
                        )}
                        sx={{ width: '100%' }}
                    />
                    <ListItemText
                        sx={{ width: '100%' }}
                        primary={
                            <PlanDisplay plan={plan} />
                        }
                    />
                </ListItemButton>
            </>
        ))
    }

    return (
        <AutomaticSheet>
            <List>
                <ListItemButton
                    key="prev"
                    sx={{ width: '100%', justifyContent: 'left', alignItems: 'left', display: 'flex', flexDirection: 'column', color: 'primary.main', borderBottom: '1px solid #f0f0f0' }}
                    onClick={() => {
                        gotoPage(plans.previous_page)
                    }}
                >
                    <ListItemText
                        primary={(
                            <>
                                <ExpandLessIcon />
                                {t('Show earlier plans')}
                            </>
                        )}
                    />
                </ListItemButton>

                {renderPlans(plans)}

                {plans.next_page && (
                    <ListItemButton
                        key="next"
                        sx={{ width: '100%', justifyContent: 'left', alignItems: 'left', display: 'flex', flexDirection: 'column', color: 'primary.main' }}
                        onClick={() => {
                            gotoPage(plans.next_page)
                        }}
                    >
                        <ListItemText
                            primary={(
                                <>
                                    <ExpandMoreIcon />
                                    {t('Show later plans')}
                                </>
                            )}
                        />
                    </ListItemButton>
                )}
            </List>
        </AutomaticSheet>
    )
}

export default Plan
