/* eslint-disable */

// @ts-nocheck

// noinspection JSUnusedGlobalSymbols

// This file was automatically generated by TanStack Router.
// You should NOT make any changes in this file as it will be overwritten.
// Additionally, you should also exclude this file from your linter and/or formatter to prevent it from being checked or modified.

// Import Routes

import { Route as rootRoute } from './routes/__root'
import { Route as TicketsImport } from './routes/tickets'
import { Route as SettingsImport } from './routes/settings'
import { Route as SearchImport } from './routes/search'
import { Route as InfoImport } from './routes/info'
import { Route as FilterImport } from './routes/filter'
import { Route as IndexImport } from './routes/index'
import { Route as PlanIndexImport } from './routes/plan.index'
import { Route as TripTripIdImport } from './routes/trip.$tripId'
import { Route as StopStopIdImport } from './routes/stop.$stopId'
import { Route as MicromobilityStopIdImport } from './routes/micromobility.$stopId'
import { Route as PlanPoiIndexImport } from './routes/plan.poi.index'
import { Route as PlanSelectSelectTypeImport } from './routes/plan.select.$selectType'
import { Route as PlanPoiSelectTypeIndexImport } from './routes/plan.poi.$selectType.index'
import { Route as PlanModeCoordinatesDatetimeTypePageIndexImport } from './routes/plan.$mode.$coordinates.$datetime.$type.$page.index'
import { Route as PlanModeCoordinatesDatetimeTypePageItinIndexImport } from './routes/plan.$mode.$coordinates.$datetime.$type.$page.$itinIndex'

// Create/Update Routes

const TicketsRoute = TicketsImport.update({
  id: '/tickets',
  path: '/tickets',
  getParentRoute: () => rootRoute,
} as any)

const SettingsRoute = SettingsImport.update({
  id: '/settings',
  path: '/settings',
  getParentRoute: () => rootRoute,
} as any)

const SearchRoute = SearchImport.update({
  id: '/search',
  path: '/search',
  getParentRoute: () => rootRoute,
} as any)

const InfoRoute = InfoImport.update({
  id: '/info',
  path: '/info',
  getParentRoute: () => rootRoute,
} as any)

const FilterRoute = FilterImport.update({
  id: '/filter',
  path: '/filter',
  getParentRoute: () => rootRoute,
} as any)

const IndexRoute = IndexImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => rootRoute,
} as any)

const PlanIndexRoute = PlanIndexImport.update({
  id: '/plan/',
  path: '/plan/',
  getParentRoute: () => rootRoute,
} as any)

const TripTripIdRoute = TripTripIdImport.update({
  id: '/trip/$tripId',
  path: '/trip/$tripId',
  getParentRoute: () => rootRoute,
} as any)

const StopStopIdRoute = StopStopIdImport.update({
  id: '/stop/$stopId',
  path: '/stop/$stopId',
  getParentRoute: () => rootRoute,
} as any)

const MicromobilityStopIdRoute = MicromobilityStopIdImport.update({
  id: '/micromobility/$stopId',
  path: '/micromobility/$stopId',
  getParentRoute: () => rootRoute,
} as any)

const PlanPoiIndexRoute = PlanPoiIndexImport.update({
  id: '/plan/poi/',
  path: '/plan/poi/',
  getParentRoute: () => rootRoute,
} as any)

const PlanSelectSelectTypeRoute = PlanSelectSelectTypeImport.update({
  id: '/plan/select/$selectType',
  path: '/plan/select/$selectType',
  getParentRoute: () => rootRoute,
} as any)

const PlanPoiSelectTypeIndexRoute = PlanPoiSelectTypeIndexImport.update({
  id: '/plan/poi/$selectType/',
  path: '/plan/poi/$selectType/',
  getParentRoute: () => rootRoute,
} as any)

const PlanModeCoordinatesDatetimeTypePageIndexRoute =
  PlanModeCoordinatesDatetimeTypePageIndexImport.update({
    id: '/plan/$mode/$coordinates/$datetime/$type/$page/',
    path: '/plan/$mode/$coordinates/$datetime/$type/$page/',
    getParentRoute: () => rootRoute,
  } as any)

const PlanModeCoordinatesDatetimeTypePageItinIndexRoute =
  PlanModeCoordinatesDatetimeTypePageItinIndexImport.update({
    id: '/plan/$mode/$coordinates/$datetime/$type/$page/$itinIndex',
    path: '/plan/$mode/$coordinates/$datetime/$type/$page/$itinIndex',
    getParentRoute: () => rootRoute,
  } as any)

// Populate the FileRoutesByPath interface

declare module '@tanstack/react-router' {
  interface FileRoutesByPath {
    '/': {
      id: '/'
      path: '/'
      fullPath: '/'
      preLoaderRoute: typeof IndexImport
      parentRoute: typeof rootRoute
    }
    '/filter': {
      id: '/filter'
      path: '/filter'
      fullPath: '/filter'
      preLoaderRoute: typeof FilterImport
      parentRoute: typeof rootRoute
    }
    '/info': {
      id: '/info'
      path: '/info'
      fullPath: '/info'
      preLoaderRoute: typeof InfoImport
      parentRoute: typeof rootRoute
    }
    '/search': {
      id: '/search'
      path: '/search'
      fullPath: '/search'
      preLoaderRoute: typeof SearchImport
      parentRoute: typeof rootRoute
    }
    '/settings': {
      id: '/settings'
      path: '/settings'
      fullPath: '/settings'
      preLoaderRoute: typeof SettingsImport
      parentRoute: typeof rootRoute
    }
    '/tickets': {
      id: '/tickets'
      path: '/tickets'
      fullPath: '/tickets'
      preLoaderRoute: typeof TicketsImport
      parentRoute: typeof rootRoute
    }
    '/micromobility/$stopId': {
      id: '/micromobility/$stopId'
      path: '/micromobility/$stopId'
      fullPath: '/micromobility/$stopId'
      preLoaderRoute: typeof MicromobilityStopIdImport
      parentRoute: typeof rootRoute
    }
    '/stop/$stopId': {
      id: '/stop/$stopId'
      path: '/stop/$stopId'
      fullPath: '/stop/$stopId'
      preLoaderRoute: typeof StopStopIdImport
      parentRoute: typeof rootRoute
    }
    '/trip/$tripId': {
      id: '/trip/$tripId'
      path: '/trip/$tripId'
      fullPath: '/trip/$tripId'
      preLoaderRoute: typeof TripTripIdImport
      parentRoute: typeof rootRoute
    }
    '/plan/': {
      id: '/plan/'
      path: '/plan'
      fullPath: '/plan'
      preLoaderRoute: typeof PlanIndexImport
      parentRoute: typeof rootRoute
    }
    '/plan/select/$selectType': {
      id: '/plan/select/$selectType'
      path: '/plan/select/$selectType'
      fullPath: '/plan/select/$selectType'
      preLoaderRoute: typeof PlanSelectSelectTypeImport
      parentRoute: typeof rootRoute
    }
    '/plan/poi/': {
      id: '/plan/poi/'
      path: '/plan/poi'
      fullPath: '/plan/poi'
      preLoaderRoute: typeof PlanPoiIndexImport
      parentRoute: typeof rootRoute
    }
    '/plan/poi/$selectType/': {
      id: '/plan/poi/$selectType/'
      path: '/plan/poi/$selectType'
      fullPath: '/plan/poi/$selectType'
      preLoaderRoute: typeof PlanPoiSelectTypeIndexImport
      parentRoute: typeof rootRoute
    }
    '/plan/$mode/$coordinates/$datetime/$type/$page/$itinIndex': {
      id: '/plan/$mode/$coordinates/$datetime/$type/$page/$itinIndex'
      path: '/plan/$mode/$coordinates/$datetime/$type/$page/$itinIndex'
      fullPath: '/plan/$mode/$coordinates/$datetime/$type/$page/$itinIndex'
      preLoaderRoute: typeof PlanModeCoordinatesDatetimeTypePageItinIndexImport
      parentRoute: typeof rootRoute
    }
    '/plan/$mode/$coordinates/$datetime/$type/$page/': {
      id: '/plan/$mode/$coordinates/$datetime/$type/$page/'
      path: '/plan/$mode/$coordinates/$datetime/$type/$page'
      fullPath: '/plan/$mode/$coordinates/$datetime/$type/$page'
      preLoaderRoute: typeof PlanModeCoordinatesDatetimeTypePageIndexImport
      parentRoute: typeof rootRoute
    }
  }
}

// Create and export the route tree

export interface FileRoutesByFullPath {
  '/': typeof IndexRoute
  '/filter': typeof FilterRoute
  '/info': typeof InfoRoute
  '/search': typeof SearchRoute
  '/settings': typeof SettingsRoute
  '/tickets': typeof TicketsRoute
  '/micromobility/$stopId': typeof MicromobilityStopIdRoute
  '/stop/$stopId': typeof StopStopIdRoute
  '/trip/$tripId': typeof TripTripIdRoute
  '/plan': typeof PlanIndexRoute
  '/plan/select/$selectType': typeof PlanSelectSelectTypeRoute
  '/plan/poi': typeof PlanPoiIndexRoute
  '/plan/poi/$selectType': typeof PlanPoiSelectTypeIndexRoute
  '/plan/$mode/$coordinates/$datetime/$type/$page/$itinIndex': typeof PlanModeCoordinatesDatetimeTypePageItinIndexRoute
  '/plan/$mode/$coordinates/$datetime/$type/$page': typeof PlanModeCoordinatesDatetimeTypePageIndexRoute
}

export interface FileRoutesByTo {
  '/': typeof IndexRoute
  '/filter': typeof FilterRoute
  '/info': typeof InfoRoute
  '/search': typeof SearchRoute
  '/settings': typeof SettingsRoute
  '/tickets': typeof TicketsRoute
  '/micromobility/$stopId': typeof MicromobilityStopIdRoute
  '/stop/$stopId': typeof StopStopIdRoute
  '/trip/$tripId': typeof TripTripIdRoute
  '/plan': typeof PlanIndexRoute
  '/plan/select/$selectType': typeof PlanSelectSelectTypeRoute
  '/plan/poi': typeof PlanPoiIndexRoute
  '/plan/poi/$selectType': typeof PlanPoiSelectTypeIndexRoute
  '/plan/$mode/$coordinates/$datetime/$type/$page/$itinIndex': typeof PlanModeCoordinatesDatetimeTypePageItinIndexRoute
  '/plan/$mode/$coordinates/$datetime/$type/$page': typeof PlanModeCoordinatesDatetimeTypePageIndexRoute
}

export interface FileRoutesById {
  __root__: typeof rootRoute
  '/': typeof IndexRoute
  '/filter': typeof FilterRoute
  '/info': typeof InfoRoute
  '/search': typeof SearchRoute
  '/settings': typeof SettingsRoute
  '/tickets': typeof TicketsRoute
  '/micromobility/$stopId': typeof MicromobilityStopIdRoute
  '/stop/$stopId': typeof StopStopIdRoute
  '/trip/$tripId': typeof TripTripIdRoute
  '/plan/': typeof PlanIndexRoute
  '/plan/select/$selectType': typeof PlanSelectSelectTypeRoute
  '/plan/poi/': typeof PlanPoiIndexRoute
  '/plan/poi/$selectType/': typeof PlanPoiSelectTypeIndexRoute
  '/plan/$mode/$coordinates/$datetime/$type/$page/$itinIndex': typeof PlanModeCoordinatesDatetimeTypePageItinIndexRoute
  '/plan/$mode/$coordinates/$datetime/$type/$page/': typeof PlanModeCoordinatesDatetimeTypePageIndexRoute
}

export interface FileRouteTypes {
  fileRoutesByFullPath: FileRoutesByFullPath
  fullPaths:
    | '/'
    | '/filter'
    | '/info'
    | '/search'
    | '/settings'
    | '/tickets'
    | '/micromobility/$stopId'
    | '/stop/$stopId'
    | '/trip/$tripId'
    | '/plan'
    | '/plan/select/$selectType'
    | '/plan/poi'
    | '/plan/poi/$selectType'
    | '/plan/$mode/$coordinates/$datetime/$type/$page/$itinIndex'
    | '/plan/$mode/$coordinates/$datetime/$type/$page'
  fileRoutesByTo: FileRoutesByTo
  to:
    | '/'
    | '/filter'
    | '/info'
    | '/search'
    | '/settings'
    | '/tickets'
    | '/micromobility/$stopId'
    | '/stop/$stopId'
    | '/trip/$tripId'
    | '/plan'
    | '/plan/select/$selectType'
    | '/plan/poi'
    | '/plan/poi/$selectType'
    | '/plan/$mode/$coordinates/$datetime/$type/$page/$itinIndex'
    | '/plan/$mode/$coordinates/$datetime/$type/$page'
  id:
    | '__root__'
    | '/'
    | '/filter'
    | '/info'
    | '/search'
    | '/settings'
    | '/tickets'
    | '/micromobility/$stopId'
    | '/stop/$stopId'
    | '/trip/$tripId'
    | '/plan/'
    | '/plan/select/$selectType'
    | '/plan/poi/'
    | '/plan/poi/$selectType/'
    | '/plan/$mode/$coordinates/$datetime/$type/$page/$itinIndex'
    | '/plan/$mode/$coordinates/$datetime/$type/$page/'
  fileRoutesById: FileRoutesById
}

export interface RootRouteChildren {
  IndexRoute: typeof IndexRoute
  FilterRoute: typeof FilterRoute
  InfoRoute: typeof InfoRoute
  SearchRoute: typeof SearchRoute
  SettingsRoute: typeof SettingsRoute
  TicketsRoute: typeof TicketsRoute
  MicromobilityStopIdRoute: typeof MicromobilityStopIdRoute
  StopStopIdRoute: typeof StopStopIdRoute
  TripTripIdRoute: typeof TripTripIdRoute
  PlanIndexRoute: typeof PlanIndexRoute
  PlanSelectSelectTypeRoute: typeof PlanSelectSelectTypeRoute
  PlanPoiIndexRoute: typeof PlanPoiIndexRoute
  PlanPoiSelectTypeIndexRoute: typeof PlanPoiSelectTypeIndexRoute
  PlanModeCoordinatesDatetimeTypePageItinIndexRoute: typeof PlanModeCoordinatesDatetimeTypePageItinIndexRoute
  PlanModeCoordinatesDatetimeTypePageIndexRoute: typeof PlanModeCoordinatesDatetimeTypePageIndexRoute
}

const rootRouteChildren: RootRouteChildren = {
  IndexRoute: IndexRoute,
  FilterRoute: FilterRoute,
  InfoRoute: InfoRoute,
  SearchRoute: SearchRoute,
  SettingsRoute: SettingsRoute,
  TicketsRoute: TicketsRoute,
  MicromobilityStopIdRoute: MicromobilityStopIdRoute,
  StopStopIdRoute: StopStopIdRoute,
  TripTripIdRoute: TripTripIdRoute,
  PlanIndexRoute: PlanIndexRoute,
  PlanSelectSelectTypeRoute: PlanSelectSelectTypeRoute,
  PlanPoiIndexRoute: PlanPoiIndexRoute,
  PlanPoiSelectTypeIndexRoute: PlanPoiSelectTypeIndexRoute,
  PlanModeCoordinatesDatetimeTypePageItinIndexRoute:
    PlanModeCoordinatesDatetimeTypePageItinIndexRoute,
  PlanModeCoordinatesDatetimeTypePageIndexRoute:
    PlanModeCoordinatesDatetimeTypePageIndexRoute,
}

export const routeTree = rootRoute
  ._addFileChildren(rootRouteChildren)
  ._addFileTypes<FileRouteTypes>()

/* ROUTE_MANIFEST_START
{
  "routes": {
    "__root__": {
      "filePath": "__root.tsx",
      "children": [
        "/",
        "/filter",
        "/info",
        "/search",
        "/settings",
        "/tickets",
        "/micromobility/$stopId",
        "/stop/$stopId",
        "/trip/$tripId",
        "/plan/",
        "/plan/select/$selectType",
        "/plan/poi/",
        "/plan/poi/$selectType/",
        "/plan/$mode/$coordinates/$datetime/$type/$page/$itinIndex",
        "/plan/$mode/$coordinates/$datetime/$type/$page/"
      ]
    },
    "/": {
      "filePath": "index.tsx"
    },
    "/filter": {
      "filePath": "filter.tsx"
    },
    "/info": {
      "filePath": "info.tsx"
    },
    "/search": {
      "filePath": "search.tsx"
    },
    "/settings": {
      "filePath": "settings.tsx"
    },
    "/tickets": {
      "filePath": "tickets.tsx"
    },
    "/micromobility/$stopId": {
      "filePath": "micromobility.$stopId.tsx"
    },
    "/stop/$stopId": {
      "filePath": "stop.$stopId.tsx"
    },
    "/trip/$tripId": {
      "filePath": "trip.$tripId.tsx"
    },
    "/plan/": {
      "filePath": "plan.index.tsx"
    },
    "/plan/select/$selectType": {
      "filePath": "plan.select.$selectType.tsx"
    },
    "/plan/poi/": {
      "filePath": "plan.poi.index.tsx"
    },
    "/plan/poi/$selectType/": {
      "filePath": "plan.poi.$selectType.index.tsx"
    },
    "/plan/$mode/$coordinates/$datetime/$type/$page/$itinIndex": {
      "filePath": "plan.$mode.$coordinates.$datetime.$type.$page.$itinIndex.tsx"
    },
    "/plan/$mode/$coordinates/$datetime/$type/$page/": {
      "filePath": "plan.$mode.$coordinates.$datetime.$type.$page.index.tsx"
    }
  }
}
ROUTE_MANIFEST_END */
