import DirectionsBusIcon from '@mui/icons-material/DirectionsBus'
import MapIcon from '@mui/icons-material/Map'
import MyLocationIcon from '@mui/icons-material/MyLocation'
import NavigateNextIcon from '@mui/icons-material/NavigateNext'
import PlaceIcon from '@mui/icons-material/Place'
import { Box, debounce, List, ListItem, ListItemButton, ListSubheader, TextField, Typography } from '@mui/material'
import { t } from 'i18next'
import { useCallback, useContext, useEffect, useState } from 'react'
import { MapRenderContext } from 'src/contexts/MapRenderContext'
import { useSearchGeocodingSearchGet } from 'src/generated/brezavta/queries'
import { SearchResult } from 'src/generated/brezavta/requests'
import { getFavourites } from 'src/lib/pois'
export const SearchSelect = ({ onSelect }: { onSelect: (result: Result) => void }) => {
    const { map } = useContext(MapRenderContext)

    const [query, setQuery] = useState('')

    const { data } = useSearchGeocodingSearchGet({
        query: {
            string: query,
            focus_lat: map?.current ? map.current.getCenter().lat : undefined,
            focus_lon: map?.current ? map.current.getCenter().lng : undefined,
        },
    })

    const debouncedSearch = useCallback(
        debounce(nextValue => setQuery(nextValue), 100),
        [],
    )

    // Remap search results to rows

    const [results, setResults] = useState<Result[]>([])

    useEffect(() => {
        const newResults = (data || []).map(resultFromSearchResult)
        setResults(newResults)
    }, [data])

    // add event listner to input field
    const search = (event: React.ChangeEvent<HTMLInputElement>) => {
        debouncedSearch(event.target.value)
    }

    const recents = getRecents()
    const favourites = getFavourites()

    return (
        <List style={{ padding: 0, width: '100%' }}>
            <ListItem>
                <TextField
                    style={{ marginTop: '5px' }}
                    label={(
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            {t('Address, place or business name')}
                        </div>
                    )}
                    onChange={search}
                    variant="outlined"
                    fullWidth
                    autoFocus // Add autoFocus prop to make it active by default
                />
            </ListItem>

            {query && results.map((result) => {
                return <SearchItem key={result.id} result={result} onSelect={onSelect} />
            })}

            {(!query) && (
                <SearchItemRow
                    icon={<MyLocationIcon />}
                    key="my-location"
                    title={t('My location')}
                    onClick={() => {
                        navigator.geolocation.getCurrentPosition(
                            (position) => {
                                const result = {
                                    id: 'my-location',
                                    location: {
                                        latitude: position.coords.latitude,
                                        longitude: position.coords.longitude,
                                    },
                                    title: t('My location'),
                                } as Result
                                onSelect(result)
                            },
                            (error) => {
                                // TODO: real error handling
                                alert('Could not get location')
                            },
                        )
                    }}
                />
            )}

            {(!query && favourites.length != 0) && (
                <>
                    <ListSubheader>
                        Favourites
                    </ListSubheader>
                    {favourites.map((poi) => {
                        return <SearchItem key={poi.id} result={poi} onSelect={onSelect} />
                    })}
                </>
            )}

            {!query && (
                <>
                    <ListSubheader>
                        {t('Recent searches')}
                    </ListSubheader>
                    {recents.map((result) => {
                        return <SearchItem key={result.id} result={result} onSelect={onSelect} />
                    })}
                </>
            )}
        </List>
    )
}

export interface Result {
    id: string
    title: string
    subtitle?: string | null
    location: { latitude: number | null, longitude: number | null } | null
    type: string
    otp_code?: string
}

function resultFromSearchResult(searchResult: SearchResult): Result {
    return {
        id: searchResult.otp_code + '',
        title: searchResult.label,
        subtitle: searchResult.description,
        location: { latitude: searchResult.lat, longitude: searchResult.lon },
        type: searchResult.type,
    }
}

function SearchItem({ result, onSelect }: { result: Result, onSelect: (result: Result) => void }) {
    return (
        <SearchItemRow
            icon={iconForResult(result)}
            key={result.id}
            title={result.title}
            subtitle={result.subtitle}
            onClick={() => {
                updateRecent(result)
                onSelect(result)
            }}
        />
    )
}

function SearchItemRow({ key, icon, title, subtitle, onClick }) {
    return (
        <ListItemButton
            key={key}
            onClick={onClick}
        >
            <Box
                display="flex"
                flexDirection="row"
                alignItems="center"
                sx={{
                    width: '100%',
                    height: '3rem',
                    borderBottom: '1px solid #f0f0f0',
                }}
            >
                <Box
                    marginRight={1}
                    sx={{ color: 'primary.main' }}
                >
                    {icon}
                </Box>
                <Box>
                    <Typography variant="body1">{title}</Typography>
                    <Typography variant="body2" color="textSecondary">{subtitle}</Typography>
                </Box>
                <Box sx={{ marginLeft: 'auto' }}>
                    <NavigateNextIcon sx={{ color: 'primary.main' }} />
                </Box>
            </Box>
        </ListItemButton>
    )
}

function iconForResult(result: Result) {
    switch (result.type) {
        case 'geocode':
            return <MapIcon />
        case 'transit_stop':
            return <DirectionsBusIcon />
        default:
            return <PlaceIcon />
    }
}

export function getRecents(): Result[] {
    const storedResults = localStorage.getItem('recents') || '[]'
    return JSON.parse(storedResults)
}

export function updateRecent(result: Result): Result[] {
    const recents = getRecents()
    const index = recents.findIndex((r) => r.title == result.title)
    if (index > -1) {
        recents.splice(index, 1)
    }
    recents.unshift(result)
    localStorage.setItem('recents', JSON.stringify(recents.slice(0, 10)))
    return recents.slice(0, 10)
}
